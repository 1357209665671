<template>
  <b-overlay :show="show" rounded="sm" spinner-small spinner-variant="primary">
    <template #overlay>
      <div class="text-center">
        <b-spinner v-show="show" type="grow" label="Loading..." />
        <h4 id="cancel-label">
          {{ $t("loader") }}
        </h4>
      </div>
    </template>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <b-link class="brand-logo">
          <img
            class="logo"
            src="../../../assets/images/logo/logo-nft-dark.png"
            style="width: 165px"
            alt="brand-logo"
            @click="goHome()"
          />
          <!-- <h2 class="brand-text text-dark  ml-1">
            NFTB+ Bussiness Lounge
          </h2> -->
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img fluid :src="imgUrl" alt="Forgot password V2" />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Forgot password-->
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title class="mb-1">
              {{ $t("resetPassword.header") }} 🔒
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t("resetPassword.body") }}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form class="auth-forgot-password-form mt-2">
                <b-form-group
                  :label="$t('register.email')"
                  label-for="forgot-password-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="forgot-password-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="forgot-password-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-alert
                  v-if="msg"
                  show
                  variant="warning"
                  class="warning"
                  style="color: red"
                >
                  {{ msg }}
                </b-alert>

                <b-button
                  variant="primary"
                  block
                  :disabled="timeOut"
                  @click="validationForm"
                >
                  {{
                    time > 0
                      ? $t("resetPasswordPage.anotherClick") + " " + time
                      : $t("resetPassword.sendResetLink")
                  }}
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <b-link :to="{ name: 'auth-login' }">
                <feather-icon icon="ChevronLeftIcon" />
                {{ $t("resetPassword.backToLogin") }}
              </b-link>
            </p>
          </b-col>
        </b-col>
        <!-- /Forgot password-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BAlert,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import { mapActions } from "vuex";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BLink,
    BAlert,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      date: 0,
      userEmail: "",
      sideImg: require("@/assets/images/pages/reset-password-03.png"),
      show: false,
      required,
      email,
      msg: null,
      timeOut: false,
    };
  },
  computed: {
    time() {
      return this.date;
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    setInterval(() => {
      if (this.time > 0) {
        this.date -= 1;
        localStorage.setItem("time", this.date);
        this.timeOut = true;
      } else {
        this.timeOut = false;
      }
    }, 1000);
  },
  methods: {
    ...mapActions({
      resetPassword: "auth/resetPassword",
    }),
    goHome() {
      this.$router.push({ name: "index" });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;
          const formData = new FormData();
          formData.append("email", this.userEmail);
          this.resetPassword(formData)
            .then((response) => {
              this.show = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.timeOut = true;
              this.date += 60;
              localStorage.setItem("time", this.date);
            })
            .catch((error) => {
              this.show = false;
              this.msg = error.response.data.message;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
              this.timeOut = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
html[dir="rtl"] svg.feather {
  transform: rotate(0);
}
</style>
<style scoped>
.img-fluid {
  max-width: 56%;
}
</style>
